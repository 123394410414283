.write-table-div table{
  width: 100%;
  border-collapse: collapse;
}
.write-table-div th,.write-table-div td{
  padding:0px 2px;
  text-align: center;
  line-height: 21px;
  font-size: 14px;
  border:1px solid #ddd;
}
.write-table-div th{
  font-weight: bold;
  background: #f4fff6;
}

.row_input{
  font-size: 14px;
  padding:0px 2px;
  line-height: 22px;
  width: 98%;
}