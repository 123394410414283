.header{
  position: relative;
  min-width:var(--min_width);
  max-width:var(--max_width);
  margin:0 auto;
}

.header_title_wrap{
  position: relative;
  height: 25px;
  line-height: 25px;
  text-align: center;
  background: #faffed;
}
.header_title_right{
  position: absolute;
  top:0px;
  right:0px;
}