.popup-background{
  position: fixed;
  left:0px;
  top:0px;
  width: 100%;
  height: 100vh;
  overflow: auto;
  z-index: 99;
}
.popup-transparent-back{
  position: fixed;
  left: 0px;
  top:0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}
.popup-wrap{
  position: absolute;
  left:0px;
  top:0px;
}
.popup-container{
  position: relative;
  background: #e6e6e6;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.popup-title-bar{
  width: 100%;
  height: 70px;
  z-index: 10;
  border-bottom: 1px solid #e6e6e6;
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.dark .popup-title-bar{
  background: #252525;
}
.popup-title-bar .con-title{
  font-size: 18px;
  margin-left: 30px;
  font-weight: 700;
  line-height: 70px;
}
.popup-title-close-btn{
  position: absolute;
  top:10px;
  right:15px;
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  color:#707070;
}
.popup-title-close-btn::after{
  display: inline-block;
  content: "\00d7";
  font-size:32px;
}

.popup-content-div{
  font-size: 15px;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-grow: 1;
  padding-bottom: 30px;
  border-radius: 10px;
}

@media screen and (max-width:700px) {
  .popup-content-div{
    padding-left: 5px;
    padding-right: 5px;
  }
}