.gnb_area{
  display: flex;
  background: #d1d1d1;
}
.gnb_area .gnb_item{
  flex-grow: 1;
  cursor: pointer;
  white-space:nowrap;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
}
.gnb_area .gnb_item.active{
  background: #0ca332;
  color: #fff;
}
.dark .gnb_area .gnb_item.active{
  background: #144821;
}

.gnb_item_ico{
  margin-right: 5px;
  height: 16px;
}

@media screen and (max-width:1200px) {
  .gnb_area .gnb_item{
    font-size: 14px;
  }
  .gnb_area .gnb_item.active{
    border-bottom:0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .dark .gnb_area .gnb_item.active{
    border-bottom:0px;
  }
  .gnb_item_ico{
    display: none;
  }
}
@media screen and (max-width:600px) {
  .gnb_area .gnb_item{
    height: 30px;
    line-height: 25px;
  }
}