:root {
  --header_height:120px;
  --min_width:350px;
  --max_width:1400px;
  --layout_border_color:#d9d9d9;
}
.container_con{
  position: relative;
  min-width:var(--min_width);
  max-width:var(--max_width);
  margin:0 auto;
}