.snb_area_wrap{
  width: 100%;
  background: #889242;
}
.dark .snb_area_wrap{
  background: #313518;
}
.snb_area{
  position: relative;
  background: #313518;
}

.snb_area_con{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.snb_area_con .snb_item{
  padding: 0 25px;
  height: 40px;
  line-height:40px;
  cursor: pointer;
  color: #ffffff;
}
.dark .snb_area_con .snb_item{
  background: #313518;
}
.snb_area_con .snb_item:hover{
  color:#fffab7;
}

.dark .snb_area_con .snb_item:hover{
  color:#cbffb9;
}
.snb_area_con .snb_item .snb_item_text{
  height: 100%;
  white-space:nowrap;
  font-size: 17px;
  font-weight: bold;
}
.snb_area_con .snb_item.active{
  color:#889242;
  background: #ffffff;
}
.dark .snb_area_con .snb_item.active{
  background: #889242;
  color:#ffffff;
}

@media screen and (max-width:1200px) {
  .snb_area_wrap{
    background: #fff;
  }
  .dark .snb_area_wrap{
    background: #000;
  }

  .snb_area_con .snb_item{
    padding: 0px 8px;
    height: 35px;
    line-height:35px;
    color: #ffffff;
  }
  .dark .snb_area_con .snb_item{
    background: #000;
    color:#cbffb9;
  }
  .snb_area_con .snb_item:hover{
    color:#7cff72;
  }
  .snb_area_con .snb_item.active{
    color:#fff;
    background: #3f8d3d;
  }
  .snb_area_con .snb_item .snb_item_text{
    font-size:13px;
  }
  .dark .snb_area_con .snb_item{
    border: 0px;
  }
}