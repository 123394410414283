.login_wrap{
  width: 300px;
  margin: 0 auto;
  margin-top:50px;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid #10ad57;
}
.login_title{
  font-size: 20px;
  text-align: center;
}
.login_table{
  width: 100%;
  border-collapse: collapse;
  margin-top:10px;
}
.login_table td{
  padding:2px 5px;
}
.login_table input {
  width: 100%;
  line-height: 25px;
}

.login_btn_wrap{
  text-align: center;
  margin-top:10px;
}
.login_btn{
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  background: #10ad57;
  color:#fff;
  cursor: pointer;
}
.login_btn:hover{
  background: #ccc;
}