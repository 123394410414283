.paging_wrap{
  margin-top:2px;
}
.paging_ul{
  display: flex;
  justify-content: center;
}
.paging_ul li{
  text-align: center;
  line-height: 23px;
}
.paging_num_btn{
  padding:0px 10px;
  font-size:14px;
  color:#80807A;
  line-height: 21px;
  border-radius: 5px;
}
.paging_num_btn img{
  height: 12px;
}
.paging_num_btn.active{
  background-color: #3FAC96;
  color:#fff;
}

.paging_number_per_page_select{
  border:1px solid #d8d8d8;
  margin-top: 1px;;
}
.paging_total_text{
  font-size: 13px;
  margin-left: 3px;
}