.btn{
  font-size: 14px;
  line-height: 28px;
  padding: 0 8px;
  height: 25px;
  display: inline-block;
  border-radius: 2px;
  cursor: pointer;
}

.btn_gray{
  background: #969696;
  color:#fff;
  border-radius: 5px;
}
.btn_gray:hover{
  background: #adadad;
}

.btn-box-center{
  display: flex;
  gap:4px;
  justify-content: center;
}