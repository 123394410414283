body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
*{
  margin:0;
  padding:0;
}
ul{
  list-style:none;
}
button{
  border:0;
}

.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.mt-1{
  margin-top:3px;
}
.ml-1{
  margin-left: 3px;
}